import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { addTrailingSlash } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import Hero from "../../components/panels/Hero";
import SlideRevealBox from "../../components/non-panels/SlideRevealBox";
import SEO from "../../components/seo";

//styles
import s from "./CaseStudiesList.module.scss";

export default function MediaList({
  location,
  data: { pageCaseStudiesPosts, strapiPnCase, backgroundImage }
}) {
  const { SEO: seo, hero } = strapiPnCase;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | Insights`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        subheading={hero[0].main_subheading}
        icon={hero[0].insight_image.childImageSharp.fixed}
      />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            {pageCaseStudiesPosts.edges.map(({ node }) => {
              let galleryObject = {
                id: node.id,
                heading: node.heading,
                image: node.case_image,
                overview: node.overview,
                slug: node.slug,
                backgroundImage
              };

              return (
                <div className={`col-lg-4 col-md-6 col-12 mb-4`}>
                  <CaseStudyGalleryLink {...galleryObject} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function CaseStudyUpper({ sliderStyle, heading, image, backgroundImage }) {
  const backgroundFluid = backgroundImage.nodes[0].fluid;
  return (
    <article style={sliderStyle} className={s.top}>
      <Img className={s.image} fluid={image.childImageSharp.fluid} />
      <BackgroundImage Tag="div" className={s.caption} fluid={backgroundFluid}>
        <h3 className={s.title}>{heading}</h3>
        <span className={`fas fa-chevron-down ${s.arrowDown}`}></span>
      </BackgroundImage>
    </article>
  );
}

function CaseStudyLower({ sliderStyle, overview, slug }) {
  return (
    <article style={sliderStyle} className={`${s.studySection} ${s.ssLower}`}>
      <p className={s.overview}>{overview}</p>
      <Link to={addTrailingSlash(`/case-studies/${slug}`)} className={s.button}>
        See More!
      </Link>
    </article>
  );
}

function CaseStudyGalleryLink({
  id,
  heading,
  image,
  overview,
  slug,
  backgroundImage,
  className
}) {
  return (
    <SlideRevealBox
      className={`${s.slider} ${className}`}
      upper={style => (
        <CaseStudyUpper
          sliderStyle={style}
          heading={heading}
          image={image}
          backgroundImage={backgroundImage}
        />
      )}
      lower={style => (
        <CaseStudyLower
          id={id}
          sliderStyle={style}
          overview={overview}
          slug={slug}
        />
      )}
    />
  );
}

// filter: { published: { eq: true } }
      // filter: { published: { eq: true } }
export const webinarListQuery = graphql`
  query caseStudyListQuery($skip: Int!, $limit: Int!, $recentLimit: Int!) {
    pageCaseStudiesPosts: allStrapiPostCase(
      sort: { fields: [created_at], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          heading
          overview
          challenge
          solution
          benefits
          slug
          case_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    recentCaseStudiesPosts: allStrapiPostCase(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
        }
      }
    }
    strapiPnCase {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        insight_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    backgroundImage: allImageSharp(
      filter: { original: { src: { regex: "/^.*hero-background-.[^-].*$/" } } }
    ) {
      nodes {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

import React from "react";

import s from "./SlideRevealBox.module.scss";

export default function SlideRevealBox({ upper, lower, className }) {
  const addedStyle = {
    width: "100%",
    height: "100%"
  };

  return (
    <article className={`${s.box} ${className}`}>
      <div className={`${s.upper} ${s.sliding}`}>{upper(addedStyle)}</div>
      <div className={`${s.lower} ${s.sliding}`}>{lower(addedStyle)}</div>
    </article>
  );
}
